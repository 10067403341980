var generic = generic || {};
var site = site || {};

site.userInfoCookie = site.userInfoCookie || {};
site.userInfoCookie.getValue =
  site.userInfoCookie.getValue ||
  function () {
    return '';
  };

(function ($) {
  site.signin = {
    isSignedIn: 0,
    $template: '',
    initSignInForm: function () {
      var self = this;
      var $login = $('.js-sign-in-overlay-login', self.$template);
      var $registration = $('.js-sign-in-overlay-registration', self.$template);
      var $form = $('#sign-in-overlay__login-form', $login);
      var $submit = $('.js-signin-submit', $login);
      var $registrationSwitch = $('.js-register-switch', $login);
      var $returnUrl = $('input[name="RETURN_URL"]', $form);
      var $forgotPassword = $('.js-forgot-password', $login);

      // Make sure that the form action points to the SSL version
      $form.attr('action', 'https://' + window.location.hostname + '/account/signin.tmpl');
      $returnUrl.val('/account/index.tmpl');

      if ($forgotPassword.length > 0) {
        site.signin.forgotPassword({
          resetPassword: true,
          emailNode: $('.js-login-email', $login),
          errorListNode: ' ',
          forgotPasswordLink: $forgotPassword
        });
      }

      $submit.once().click(function (e) {
        e.preventDefault();
        $form.submit();

        return false;
      });

      $registrationSwitch.once().click(function () {
        $login.hide();
        $registration.once('form-initialized', function () {
          self.initRegistrationForm();
        });
        $registration.show();
      });
    },

    initRegistrationForm: function () {
      var self = this;
      var $registration = $('.js-sign-in-overlay-registration', self.$template);
      var $selectBoxes = $('.selectbox', $registration);
      var $login = $('.js-sign-in-overlay-login', self.$template);
      var $form = $('#sign-in-overlay__registration-form', $registration);
      var $showPass = $('input[name="show_pass"]', $registration);
      var $password = $('input[name="PASSWORD"]', $registration);
      var $submit = $('.js-registration-submit', $registration);
      var $loginSwitch = $('.js-login-switch', $registration);
      var $returnUrl = $('input[name="RETURN_URL"]', $registration);

      // Make sure that the form action points to the SSL version
      $form.attr('action', 'https://' + window.location.hostname + '/account/signin.tmpl');
      $selectBoxes.trigger('selectbox:loadOnDemand');
      $returnUrl.val('/account/index.tmpl');

      $showPass.removeAttr('checked');
      $showPass.click(function () {
        var isChecked = $(this).is(':checked');

        $password[0].type = isChecked ? 'text' : 'password';
      });

      $submit.once().click(function (e) {
        e.preventDefault();
        $form.submit();

        return false;
      });

      $loginSwitch.click(function () {
        $registration.hide();
        $login.show();
      });
    },

    initAccountLinks: function () {
      var self = this;
      var $login = $('.js-sign-in-overlay-login', self.$template);
      var $registration = $('.js-sign-in-overlay-registration', self.$template);
      var $account = $('.js-sign-in-overlay-account-links', self.$template);
      var $welcome = $('.js-sign-in-overlay-welcome', self.$template);
      var $userName = $('.js-sign-in-overlay-account-name', self.$template);

      $(self.$template).addClass('is_signed_in');
      var user = site.userInfoCookie.getValue('first_name');

      if (!_.isUndefined(user) && !_.isNull(user)) {
        $userName.html(user);
        $welcome.show();
      } else {
        $welcome.hide();
      }
      $login.hide();
      $registration.hide();
      $account.show();
    },

    init: function () {
      var self = this;

      if (parseInt(site.signin.isSignedIn) === 0) {
        self.initSignInForm();
      } else if (parseInt(site.signin.isSignedIn) === 1) {
        self.initAccountLinks();
      }
    }
  };

  Drupal.behaviors.signInOverlayV1 = {
    attach: function (context) {
      site.signin.isSignedIn = _.isNull(site.userInfoCookie.getValue('signed_in'))
        ? 0
        : site.userInfoCookie.getValue('signed_in');
      site.signin.$template = $('.sign-in', context);
      site.signin.init();
    }
  };
})(jQuery);
